import HomeIcon from '@material-ui/icons/Home'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import SearchIcon from '@material-ui/icons/Search'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import CloudIcon from '@material-ui/icons/Cloud'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import TodayIcon from '@material-ui/icons/Today'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import EditIcon from '@material-ui/icons/Edit'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import { IoIosRocket } from 'react-icons/io'
import BarChartIcon from '@material-ui/icons/BarChart'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'

export default {
  candidate: {
    menus: [
      {
        description: 'Inicio',
        icon: () => <HomeIcon fontSize="large" />,
        href: '/inicio',
      },
      {
        description: 'Vagas',
        icon: () => <IoIosRocket fontSize="32px" />,
        href: '/candidato/vagas',
        links: [
          {
            description: 'Vagas',
            icon: () => <IoIosRocket fontSize="32px" />,
            href: '/candidato/vagas',
          },
          {
            description: 'Entrevistas',
            icon: () => <EventAvailableIcon fontSize="32px" />,
            href: '/candidato/entrevistas',
          },
        ],
      },
      {
        description: 'Provas',
        icon: () => <PlaylistAddCheckIcon fontSize="large" />,
        href: '/provas',
      },
      {
        description: 'Cursos',
        icon: () => <PlayCircleOutlineIcon fontSize="large" />,
        href: '/cursos',
      },
      {
        description: 'Ponto Eletrônico',
        icon: () => <QueryBuilderIcon fontSize="large" />,
        href: '/ponto-eletronico',
      },
    ],
    profile: [
      /* {
        description: 'Benefícios',
        icon: () => <StarIcon />,
        href: '/beneficios',
      },
      {
        description: 'Indique um amigo',
        icon: () => <GroupAddIcon />,
        href: '/indique-um-amigo',
      }, */
      {
        description: 'Editar perfil',
        icon: () => <EditIcon />,
        href: '/editar/cadastro-do-candidato',
      },
      {
        description: 'Editar login',
        icon: () => <EditIcon />,
        href: '/editar/login',
      },
    ],
  },
  company: {
    menus: [
      {
        description: 'Inicio',
        icon: () => <HomeIcon fontSize="large" />,
        href: '/inicio',
      },
      {
        description: 'Buscar candidato',
        icon: () => <SearchIcon fontSize="large" />,
        href: '/busca',
      },
      {
        description: 'Entrevistas',
        icon: () => <PeopleAltIcon fontSize="large" />,
        href: '/entrevistas',
        links: [
          {
            description: 'Processo seletivo',
            icon: () => <SupervisedUserCircleIcon />,
            href: '/processo-seletivo',
          },
          {
            description: 'Agenda',
            icon: () => <TodayIcon />,
            href: '/agenda',
          },
          {
            description: 'Vagas',
            icon: () => <IoIosRocket fontSize="24px" />,
            href: '/vagas',
          },
        ],
      },
      {
        description: 'Equipe na nuvem',
        icon: () => <CloudIcon fontSize="large" />,
        href: '/equipe-na-nuvem',
      },
      {
        description: 'Ponto Eletrônico',
        icon: () => <QueryBuilderIcon fontSize="large" />,
        href: '/ponto-eletronico',
      },
      {
        description: 'Relatórios',
        icon: () => <BarChartIcon fontSize="large" />,
        href: '/relatorios',
      },
    ],
    profile: [
      {
        description: 'Editar perfil',
        icon: () => <EditIcon />,
        href: '/editar/cadastro-da-empresa',
      },
      {
        description: 'Editar login',
        icon: () => <EditIcon />,
        href: '/editar/login',
      },
    ],
  },
}

import { makeStyles } from "@material-ui/core";

import LogoWorc from "../Icon/LogoWorc";

const useStylesHeaderMobile = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    overflow: 'hidden',
    padding: ' 16px 0'
  }
}))

function HeaderDisconnectedMobile() {

  const classes = useStylesHeaderMobile();

  return (
    <header className={classes.container}>
      <a href="/entrar" style={{display: 'flex', justifyContent: 'center'}}>
        <LogoWorc size={60}/>
      </a>
    </header>
  )
}

export default HeaderDisconnectedMobile;

import { makeStyles } from "@material-ui/core";

import LogoWorc from "../Icon/LogoWorc";

const useStylesHeaderDesktop = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    overflow: 'hidden',
    padding: '16px',
    height: 90,
  }
}))


function HeaderDisconnectedDesktop() {
  const classes = useStylesHeaderDesktop();
  return (
    <header className={classes.container}>
      <a href="/entrar">
        <LogoWorc size={64} />
      </a>
    </header>
  )
}

export default HeaderDisconnectedDesktop;

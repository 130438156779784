import {
  Box, IconButton, Paper, TextField,
} from '@material-ui/core'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import SendIcon from '@material-ui/icons/Send'
import { whatsAppNumber } from 'utils'

const useStyles = makeStyles((theme) => ({
  whatsAppButton: {
    position: 'fixed',
    bottom: ({ visible }) => (visible ? 20 : -100),
    opacity: ({ visible }) => (visible ? 1 : 0),
    right: 20,
    backgroundColor: 'limegreen',
    padding: 8,
    fontSize: 40,
    transition: 'all .4s ease',
    color: 'white',
    zIndex: theme.zIndex.drawer + 2,
    '&:hover': {
      backgroundColor: 'darkgreen',
    },
  },
  whatsAppBox: {
    position: 'fixed',
    right: 20,
    bottom: 80,
    width: 300,
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '15px 15px 12px -2px rgba(0, 0, 0, 0.2)',
  },
  whatsAppBoxHeader: {
    backgroundColor: '#009688',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3px 5px',

    '& svg': {
      cursor: 'pointer',
    },
  },
  whatsAppBoxContent: {
    backgroundColor: '#e5ddd5',
    padding: 10,

    '& .MuiPaper-root': {
      borderRadius: '6px',
      padding: 10,
    },
  },
  whatsAppBoxFooter: {
    padding: 10,
    backgroundColor: '#ffff',
    display: 'flex',
    alignItems: 'center',
  },
  sendButton: {
    margin: '0 0 0 10px',
    color: '#51585c',
    fontSize: 30,
  },
}))

function WhatsAppChat({ visible }) {
  const classes = useStyles({ visible })
  const [open, setOpen] = useState(false)
  const [text, setText] = useState('')

  function handleOpenWhatsAppChat() {
    const number = process.env.NEXT_PUBLIC_WHATSAPP_NUMBER || whatsAppNumber
    const url = `https://api.whatsapp.com/send?1=pt_BR&phone=+${number}&text=${text}`
    window.open(url, '_blank')
    setText('')
    return setOpen(false)
  }

  return (
    <>
      <Box className={classes.whatsAppBox} hidden={!open}>
        <Box className={classes.whatsAppBoxHeader}>
          Estamos no WhatsApp
          <CloseIcon onClick={() => setOpen(false)} />
        </Box>
        <Box className={classes.whatsAppBoxContent}>
          <Paper>Como podemos ajudá-lo(a)?</Paper>
        </Box>
        <Box className={classes.whatsAppBoxFooter}>
          <TextField
            placeholder="Digite sua dúvida ou mensagem"
            variant="outlined"
            fullWidth
            multiline
            rows={2}
            value={text}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleOpenWhatsAppChat()
              }
            }}
            onChange={({ target: { value } }) => setText(value)}
          />
          <IconButton className={classes.sendButton} onClick={handleOpenWhatsAppChat}>
            <SendIcon fontSize="inherit" color="inherit" />
          </IconButton>
        </Box>
      </Box>
      <IconButton className={classes.whatsAppButton} onClick={() => setOpen(((prevState) => !prevState))}>
        <WhatsAppIcon color="inherit" fontSize="inherit" />
      </IconButton>
    </>
  )
}

export default WhatsAppChat

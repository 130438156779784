import { useState } from 'react'
import Link from 'next/link'
import { nanoid } from 'nanoid'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import {
  Avatar, Box, Grid, makeStyles,
} from '@material-ui/core'

import useAuth from 'context/useAuth'

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
    color: '#fff',
  },
  profileMenu: {
    color: '#fff',
  },
  profileDropdown: {
    position: 'absolute',
    backgroundColor: '#f9f9f9',
    minWidth: '200px',
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
    zIndex: theme.zIndex.drawer + 2,
    top: '50px',
    borderRadius: '8px',
    right: '0px',
    fontSize: 'initial',
  },
  profileDropdownMenu: {
    color: '#707070',
    padding: '16px',
    textDecoration: 'none',
    display: 'block',
    borderBottom: '1px solid #e3e3e3',
    cursor: 'pointer',
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: '#e3e3e3',
    },
    '&:first-child:hover': {
      borderRadius: '8px 8px 0 0',
    },
    '&:last-child:hover': {
      borderRadius: '0 0 8px 8px',
    },
  },
  profileDropdownMenuLogout: {
    color: '#F25858',
    borderRadius: '0 0 8px 8px',
  },
  emailSession: {
    '@media(min-width: 1280px)': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '120px',
    },
    '@media(max-width: 600px)': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '120px',
    },
  },
}))

function ProfileOptions({ session, singOut, options }) {
  const { registerWithSocialNetwork } = useAuth()

  const [open, setOpen] = useState()
  const classes = useStyles()

  function ProfileDropDown(props) {
    return (
      <Link href={props.href} key={nanoid()}>
        <a className={classes.profileDropdownMenu}>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Box mr={1}>
                <props.icon />
              </Box>
            </Grid>
            <Grid item>
              {props.description}
            </Grid>
          </Grid>
        </a>
      </Link>
    )
  }

  return (
    <div className={classes.main} onClick={() => setOpen(!open)}>
      <Avatar src={session.avatar} />
      <Box px={1} className={classes.emailSession}>{session.email}</Box>
      {
          open
            ? <MdKeyboardArrowUp fontSize="24px" />
            : <MdKeyboardArrowDown fontSize="24px" />
        }
      {open && (
        <div className={classes.profileDropdown}>
          {options.map((option) => {
            const { href, icon, description } = option

            if (!session.isCompany) {
              if (session.candidate.is_social_auth) {
                if (option.description !== 'Editar login') { return <ProfileDropDown href={href} icon={icon} description={description} /> }
              } else { return <ProfileDropDown href={href} icon={icon} description={description} /> }
            }

            return <ProfileDropDown href={href} icon={icon} description={description} />
            /* session.candidate.is_social_auth && option.description !== 'Editar login'
              ? <ProfileDropDown href={href} icon={icon} description={description} />
              : <ProfileDropDown href={href} icon={icon} description={description} /> */
          })}

          <a className={`${classes.profileDropdownMenu} ${classes.profileDropdownMenuLogout}`} onClick={singOut}>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Box mr={1}>
                  <ExitToAppIcon />
                </Box>
              </Grid>
              <Grid item>
                Sair
              </Grid>
            </Grid>
          </a>
        </div>
      )}
    </div>
  )
}

ProfileOptions.defaultProps = {
  session: {},
}

export default ProfileOptions

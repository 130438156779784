import React, { useCallback, useEffect, useState } from 'react'
import {
  Backdrop, Box, Button, CircularProgress, Container, makeStyles, useTheme,
} from '@material-ui/core'
import TimerIcon from '@material-ui/icons/Timer'
import Cookies from 'js-cookie'
import WhatsAppChat from 'components/WhatsAppChat'
import ScrollButton from 'components/ScrollButton'
import PropTypes from 'prop-types'
import { loadCSS } from 'fg-loadcss'
import useDefaultLoading from 'context/useDefaultLoading'
import useAuth from '../../context/useAuth'
import Header, { HeaderDisconnected } from '../Header'
import Snackbar from './Snackbar'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    padding: 0,
    minHeight: '100vh',
    paddingBottom: theme.spacing(3),
    overflowX: 'hidden',
  },
  alertImpersonate: {
    position: 'fixed',
    bottom: 0,
    background: '#ffc107',
    padding: '20px',
    margin: '0 auto',
    width: '80%',
    left: '50%',
    marginLeft: '-40%',
    textAlign: 'center',
    borderRadius: '7px 7px 0 0',
    zIndex: '9999',
    '& p': {
      marginBottom: '10px',
    },
  },
  timer: {
    float: 'right',
    width: '60px',
    height: '60px',
    position: 'relative',
    top: '-20px',
    right: '-20px',
    paddingTop: '10px',
    cursor: 'pointer',
    marginLeft: '-60px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    // manual fix for dont change color when put theme secondary
    color: '#41C0F0',
  },
}))

function LayoutDefault({ children, fullPage }) {
  const { defaultLoading } = useDefaultLoading()
  const [impersonated, setImpersonated] = useState()
  const { session, singOut } = useAuth()
  const [isHide, setIsHide] = useState(false)
  const theme = useTheme()
  const classes = useStyles(theme)
  const [isScrollY, setIsScrollY] = useState(null)
  const [isFloatButtonVisible, setIsFloatButtonVisible] = useState(true)

  // Hide float button when scroll down
  const onScroll = useCallback(
    (e) => {
      const window = e.currentTarget
      if (isScrollY > window.scrollY) {
        setIsFloatButtonVisible(true)
      } else if (isScrollY < window.scrollY) {
        setIsFloatButtonVisible(false)
      }
      setIsScrollY(window.scrollY)
    }, [isScrollY],
  )

  useEffect(() => {
    if (Cookies.get('impersonate')) {
      setImpersonated(true)
    }
  }, [])

  useEffect(() => {
    if (!window) return null
    setIsScrollY(window.scrollY)
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [onScroll])

  useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    )

    return () => {
      node.parentNode.removeChild(node)
    }
  }, [])

  function hideMe() {
    setIsHide(true)
    setTimeout(() => {
      setIsHide(false)
      clearTimeout()
    }, 15000)
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={defaultLoading}><CircularProgress color="inherit" /></Backdrop>
      <Snackbar />
      <Box display="block" className={classes.root} style={{ paddingBottom: fullPage ? 0 : theme.spacing(3) }}>
        <Box ml={-3} mr={-3} mb={fullPage ? 0 : 3} style={{ borderBottom: '1px solid #8596a3' }}>
          <Container maxWidth="xl">
            {session ? <Header /> : <HeaderDisconnected />}
          </Container>
        </Box>
        <Container
          maxWidth={fullPage ? null : 'xl'}
          style={{
            paddingLeft: fullPage ? 0 : theme.spacing(2),
            paddingRight: fullPage ? 0 : theme.spacing(2),
          }}
        >
          {children}
        </Container>
        {impersonated && !isHide && (
          <Box display="block" className={classes.alertImpersonate}>
            <i className={classes.timer} onClick={() => hideMe()}><TimerIcon /></i>
            <p>você está usando uma conta impersonada</p>
            <Button variant="contained" color="primary" onClick={() => singOut()}>
              FAÇA LOGOFF QUANDO TERMINAR
            </Button>
          </Box>
        )}
      </Box>
      <WhatsAppChat visible={isFloatButtonVisible} />
      <ScrollButton />
    </>
  )
}

LayoutDefault.propTypes = {
  children: PropTypes.node.isRequired,
  fullPage: PropTypes.bool.isRequired,
}

export default LayoutDefault

import { nanoid } from 'nanoid'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Box, colors, Fade, Grid, LinearProgress as MuiLinearProgress, makeStyles, } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import LogoWorc from '../Icon/LogoWorc'
import ProfileOptions from './ProfileOptions'

const LinearProgress = withStyles(() => ({
  barColorPrimary: {
    backgroundColor: colors.lightBlue[900],
  },
}))(MuiLinearProgress)

const useStylesCandidate = makeStyles((theme) => ({
  logo: {
    padding: `0 ${theme.spacing(2)}px 0 0`,
  },
  menu: {
    padding: '26px',
  },
  menuActive: {
    borderBottom: '2px solid #fff',
    color: theme.palette.primary.main,
  },
  menuLink: {
    textDecoration: 'none',
    color: '#fff',
  },
  menuLinkActive: {
    fontWeight: 'bold',
  },
}))

const useStylesCompany = makeStyles((theme) => ({
  logo: {
    padding: `0 ${theme.spacing(2)}px 0 0`,
  },
  menu: {
    padding: '28px 10px 24px 10px'
  },
  menuActive: {
    borderBottom: `2px solid ${theme.palette.primary.light}`,
    color: theme.palette.primary.light,
  },
  menuLink: {
    textDecoration: 'none',
    color: colors.grey[400],
    fill: colors.grey[400],
  },
  menuLinkActive: {
    color: theme.palette.primary.light,
    fill: theme.palette.primary.light,
  },
}))

function HeaderDesktop({
                         linksByProfile,
                         session,
                         singOut,
                       }) {
  const router = useRouter()
  const classes = session.isCompany
    ? useStylesCompany()
    : useStylesCandidate()

  const classHeader = session.isCompany
    ? 'Header__company'
    : 'Header__candidate'

  function isActiveMenu(link) {
    return (
      router.pathname === link.href
      || router.asPath === link.href
      || router.asPath.startsWith(link.href)
      || (link.links && link.links.map(({ href }) => href).includes(router.pathname))
    )
  }

  return (
    <Box>
      <nav className={`Header ${classHeader}`}>
        <Grid container alignItems="center">
          <Grid item className={classes.logo}>
            <a href="/inicio">
              <LogoWorc size={64}/>
            </a>
          </Grid>
          <Grid>
            <Grid container key={nanoid()}>
              {linksByProfile.menus.map((link) => (
                <Grid key={`link-${link.href}`} item
                      className={`${classes.menu} ${isActiveMenu(link) ? classes.menuActive : ''}`}>
                  {!link.links && (
                    <Link href={link.href}>
                      <a className={`${classes.menuLink} ${isActiveMenu(link) ? classes.menuLinkActive : ''}`}>
                        <Grid container direction="row" alignItems="center">
                          <Grid item>
                            <Box mr={1}>
                              <link.icon/>
                            </Box>
                          </Grid>
                          <Grid item>
                            {link.description}
                          </Grid>
                        </Grid>
                      </a>
                    </Link>
                  )}
                  {link.links && (
                    <div
                      className={`Header__dropdown ${classes.menuLink} ${isActiveMenu(link) ? classes.menuLinkActive : ''}`}>
                      <a className="Header__dropbtn">
                        <Grid container direction="row" alignItems="center">
                          <Grid item>
                            <Box mr={1}>
                              <link.icon/>
                            </Box>
                          </Grid>
                          <Grid item>
                            {link.description}
                          </Grid>
                        </Grid>
                      </a>
                      <div className="Header__dropdown-content">
                        {link.links.map((link) => (
                          <Link href={link.href} key={nanoid()}>
                            <a>
                              <Grid container direction="row" alignItems="center">
                                <Grid item>
                                  <Box mr={1}>
                                    <link.icon/>
                                  </Box>
                                </Grid>
                                <Grid item>
                                  {link.description}
                                </Grid>
                              </Grid>
                            </a>
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <ProfileOptions
            session={session}
            options={linksByProfile.profile}
            singOut={singOut}
          />
        </Grid>
      </nav>
    </Box>
  )
}

export default HeaderDesktop

import MenuIcon from '@material-ui/icons/Menu'
import InboxIcon from '@material-ui/icons/Inbox'
import {
  Box, List, ListItem, ListItemIcon, ListItemText, makeStyles, SwipeableDrawer,
} from '@material-ui/core'
import { green, orange } from '@material-ui/core/colors'
import { useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { v4 as uuid } from 'uuid'
import ProfileOptions from './ProfileOptions'
import LogoWorc from '../Icon/LogoWorc'

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  logo: {

  },
  menuIcon: {
    color: '#fff',
    padding: theme.spacing(2),
    paddingRight: 0,
  },
  menu: {
    background: theme.palette.primary.main,
    height: '100vh',
  },
  list: {
    color: '#fff',
  },
  listIcon: {
    color: '#fff',
  },
}))

function HeaderMobile({
  linksByProfile,
  session,
  singOut,
}) {
  const router = useRouter()
  const classes = useStyles()
  const [openDrawer, setOpenDrawer] = useState(false)

  function isActiveMenu(link) {
    return (
      router.pathname === link.href
      || (link.links && link.links.map(({ href }) => href).includes(router.pathname))
    )
  }

  return (
    <nav className={classes.header}>
      <Box alignItems="center" display="flex">
        <Box width={1}>
          <Box>
            <a href="/inicio">
              <LogoWorc size={36} />
            </a>
          </Box>
        </Box>
        <Box onClick={() => setOpenDrawer(!openDrawer)}>
          <MenuIcon className={classes.menuIcon} />
        </Box>
      </Box>
      <SwipeableDrawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
      >
        <Box className={classes.menu}>
          <Box p={2}>
            <ProfileOptions
              session={session}
              options={linksByProfile.profile}
              singOut={singOut}
            />
          </Box>
          <List className={classes.list}>
            {linksByProfile.menus.map((link) => (
              <>
                {!link.links && (
                  <Link href={link.href} key={uuid()}>
                    <ListItem button className={isActiveMenu(link) ? 'Header__link-active' : ''}>
                      <ListItemIcon className={classes.listIcon}>
                        <link.icon />
                      </ListItemIcon>
                      <ListItemText primary={link.description} />
                    </ListItem>
                  </Link>
                )}
                {link.links && link.links.map((link) => (
                  <Link href={link.href} key={uuid()}>
                    <ListItem button className={isActiveMenu(link) ? 'Header__link-active' : ''}>
                      <ListItemIcon className={classes.listIcon}>
                        <link.icon />
                      </ListItemIcon>
                      <ListItemText primary={link.description} />
                    </ListItem>
                  </Link>
                ))}
              </>
            ))}
          </List>
        </Box>
      </SwipeableDrawer>
    </nav>
  )
}

export default HeaderMobile

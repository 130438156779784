import useAuth from '../../context/useAuth'

import links from './links'
import { Hidden } from '@material-ui/core'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'
import HeaderDisconnectedDesktop from './HeaderDisconnectedDesktop'
import HeaderDisconnectedMobile from './HeaderDisconnectedMobile'

export default function Header() {
  const { session, singOut } = useAuth()

  if (!session) {
    return null
  }

  const linksByProfile = session.isCompany
    ? links.company
    : links.candidate

  return (
    <>
      <Hidden mdDown>
        <HeaderDesktop session={session} linksByProfile={linksByProfile} singOut={singOut} />
      </Hidden>
      <Hidden lgUp>
        <HeaderMobile session={session} linksByProfile={linksByProfile} singOut={singOut} />
      </Hidden>
    </>
  )
}

export function HeaderDisconnected() {
  return (
    <>
      <Hidden smDown>
        <HeaderDisconnectedDesktop />
      </Hidden>
      <Hidden mdUp>
        <HeaderDisconnectedMobile />
      </Hidden>
    </>
  )
}

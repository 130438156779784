import React, { useState } from 'react'
import {
  Fab,
  Slide,
} from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  fab: {
    display: 'none',
    '&:hover': {

      backgroundColor: theme.palette.primary.main,
      opacity: 0.9,
      cursor: 'pointer',
    },
    '@media(max-width: 998px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      bottom: theme.spacing(2.4),
      right: theme.spacing(2.5),
    },

  },
  icon: {
    color: '#fff',
    fontSize: '45px',
  },
}))

const ScrollButton = () => {
  const classes = useStyles()
  const [visible, setVisible] = useState(false)

  if (typeof window === 'undefined') {
    return null
  }

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  window.addEventListener('scroll', toggleVisible)

  return (
    <div
      style={
        {
          position: 'absolute',
          zIndex: 10,
          display: visible ? 'inline' : 'none',
        }
      }
      role="button"
      tabIndex={0}
      onClick={scrollToTop}
      onKeyPress={scrollToTop}
    >
      <Slide
        direction="up"
        in={visible}
        timeout={300}
        mountOnEnter
        unmountOnExit
      >
        <Fab color="primary" className={classes.fab}>
          <ExpandLess className={classes.icon} />
        </Fab>
      </Slide>
    </div>

  )
}

export default ScrollButton

/* eslint-disable react/jsx-props-no-spreading */
import MuiSnackbar from '@material-ui/core/Snackbar'
import { Alert } from '@material-ui/lab'
import useSnackbar from 'context/useSnackbar'

function Snackbar() {
  const { snackbar, updateSnackbar } = useSnackbar()

  return (
    <MuiSnackbar
      {...snackbar}
      onClose={() => updateSnackbar({ open: false })}
      message={!snackbar.severity && snackbar.message}
    >
      {snackbar.severity && (
      <Alert
        onClose={() => updateSnackbar({ open: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </Alert>
      )}
    </MuiSnackbar>
  )
}

export default Snackbar

export function isLastItem(list = [], index = 0) {
  return list.length == (index + 1)
}

export const contraPassRegisterCompany = '@PeR4c03$'
export const whatsAppNumber = '5511993941000'

export function convertMoneyToNumber(value = '') {
  if (typeof value === 'number') {
    return value
  }

  return parseFloat(
    value
      .replace(/\./g, '')
      .replace(',', '.')
      .replace('R$ ', ''),
  )
}

export const UFS = ['UF', 'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO']

export const JOBS = [
  { title: '1º Cozinheiro', value: 11, checked: false },
  { title: '2º Cozinheiro', value: 12, checked: false },
  { title: 'Analista de T.I.', value: 47, checked: false },
  { title: 'Administrativo', value: 53, checked: false },
  { title: 'Atendente', value: 22, checked: false },
  { title: 'Auxiliar de Cozinha', value: 24, checked: false },
  { title: 'Auxiliar de Limpeza', value: 19, checked: false },
  { title: 'Auxiliar de serviços gerais', value: 42, checked: false },
  { title: 'Açougueiro', value: 38, checked: false },
  { title: 'Barback', value: 8, checked: false },
  { title: 'Bartender', value: 7, checked: false },
  { title: 'Cabelereiro', value: 58, checked: false },
  { title: 'Caixa', value: 10, checked: false },
  { title: 'Camareiro (HOTEL)', value: 33, checked: false },
  { title: 'Chapeiro', value: 18, checked: false },
  { title: 'Chef', value: 14, checked: false },
  { title: 'Chefe de Bar', value: 6, checked: false },
  { title: 'Churrasqueiro', value: 43, checked: false },
  { title: 'Comprador', value: 49, checked: false },
  { title: 'Confeiteiro', value: 15, checked: false },
  { title: 'Controlador de acesso', value: 37, checked: false },
  { title: 'Coordenador/Supervisor', value: 23, checked: false },
  { title: 'Copeira (o)', value: 25, checked: false },
  { title: 'Cumim', value: 2, checked: false },
  { title: 'Enfermeiro (a)', value: 27, checked: false },
  { title: 'Entregador', value: 51, checked: false },
  { title: 'Estoquista', value: 21, checked: false },
  { title: 'Garçom', value: 3, checked: false },
  { title: 'Gerente', value: 5, checked: false },
  { title: 'Hostess', value: 1, checked: false },
  { title: 'Manicure', value: 57, checked: false },
  { title: 'Manobrista', value: 50, checked: false },
  { title: 'Manutenção', value: 30, checked: false },
  { title: 'Marketing', value: 46, checked: false },
  { title: 'Maître', value: 4, checked: false },
  { title: 'Mensageiro (HOTEL)', value: 35, checked: false },
  { title: 'Motoboy', value: 32, checked: false },
  { title: 'Motorista', value: 48, checked: false },
  { title: 'Nutricionista', value: 26, checked: false },
  { title: 'Operador de delivery', value: 52, checked: false },
  { title: 'Padeiro', value: 17, checked: false },
  { title: 'Padeiro', value: 44, checked: false },
  { title: 'Peixeiro', value: 40, checked: false },
  { title: 'Pia', value: 36, checked: false },
  { title: 'Pizzaiolo', value: 45, checked: false },
  { title: 'Recepcionista (HOTEL)', value: 34, checked: false },
  { title: 'Repositor', value: 29, checked: false },
  { title: 'Saladeiro', value: 16, checked: false },
  { title: 'Segurança', value: 20, checked: false },
  { title: 'Sommelier', value: 9, checked: false },
  { title: 'Sushiman', value: 39, checked: false },
  { title: 'Técnico de Refrigeração', value: 31, checked: false },
  { title: 'Zelador', value: 41, checked: false },
  { title: 'outro', value: 28, checked: false },
]

export const PretenceSalary = [
  '1000-1500',
  '1500-2000',
  '2000-2500',
  '2500-3000',
  '3000-3500',
  '3500-4000',
  '4000-4500',
  '4500-5000',
  '5000-5500',
  '5500-6000',
  '6000-6500',
  '6500-7000',
  '7000+',
]

export const Schooling = [
  'Não tenho formação',
  'Fundamental completo',
  'Fundamental incompleto',
  'Médio completo',
  'Médio incompleto',
  'Superior(Graduação) completo',
  'Superior(Graduação) incompleto',
  'Pós - graduação completo',
  'Pós - graduação incompleto',
  'Mestrado completo',
  'Mestrado incompleto',
  'Doutorado completo',
  'Doutorado incompleto',
]

export const schoolingList = [
  { value: 'nao-tenho-formacao', title: 'Não tenho formação' },
  { value: 'fundamental-completo', title: 'Fundamental completo' },
  { value: 'fundamental-incompleto', title: 'Fundamental incompleto' },
  { value: 'medio-completo', title: 'Médio completo' },
  { value: 'medio-incompleto', title: 'Médio incompleto' },
  { value: 'superior-completo', title: 'Superior(Graduação) completo' },
  { value: 'superior-incompleto', title: 'Superior(Graduação) incompleto' },
  { value: 'pos-completo', title: 'Pós - graduação completo' },
  { value: 'pos-incompleto', title: 'Pós - graduação incompleto' },
  { value: 'mestrado-completo', title: 'Mestrado completo' },
  { value: 'mestrado-incompleto', title: 'Mestrado incompleto' },
  { value: 'doutorado-completo', title: 'Doutorado completo' },
  { value: 'doutorado-incompleto', title: 'Doutorado incompleto' },
]

export const randomColors = [
  'rgba(8, 163, 33, 0.4)',
  'rgba(175, 33, 5, 0.4)',
  'rgba(1, 31, 102, 0.4)',
  'rgba(2, 97, 114, 0.4)',
  'rgba(175, 5, 30, 0.4)',
  'rgba(82, 158, 11, 0.4)',
  'rgba(119, 135, 1, 0.4)',
  'rgba(0, 105, 117, 0.4)',
  'rgba(175, 5, 30, 0.4)',
  'rgba(214, 110, 6, 0.4)',
  'rgba(0, 140, 81, 0.4)',
  'rgba(62, 11, 132, 0.4)',
  'rgba(130, 1, 57, 0.4)',
  'rgba(8, 160, 89, 0.4)',
  'rgba(4, 25, 142, 0.4)',
  'rgba(13, 170, 100, 0.4)',
  'rgba(170, 5, 27, 0.4)',
  'rgba(229, 100, 9, 0.4)',
  'rgba(21, 142, 12, 0.4)',
  'rgba(0, 21, 119, 0.4)',
  'rgba(226, 6, 131, 0.4)',
  'rgba(0, 153, 89, 0.4)',
  'rgba(178, 0, 74, 0.4)',
  'rgba(0, 8, 122, 0.4)',
  'rgba(48, 137, 13, 0.4)',
  'rgba(160, 40, 16, 0.4)',
  'rgba(10, 76, 147, 0.4)',
  'rgba(232, 2, 140, 0.4)',
  'rgba(229, 20, 187, 0.4)',
  'rgba(69, 14, 163, 0.4)',
  'rgba(232, 6, 157, 0.4)',
  'rgba(5, 119, 21, 0.4)',
  'rgba(4, 44, 96, 0.4)',
  'rgba(7, 22, 142, 0.4)',
  'rgba(110, 168, 16, 0.4)',
  'rgba(35, 0, 112, 0.4)',
  'rgba(10, 130, 80, 0.4)',
  'rgba(1, 104, 18, 0.4)',
  'rgba(1, 102, 119, 0.4)',
  'rgba(6, 55, 107, 0.4)',
  'rgba(186, 90, 1, 0.4)',
  'rgba(226, 96, 20, 0.4)',
  'rgba(28, 137, 6, 0.4)',
  'rgba(14, 81, 140, 0.4)',
  'rgba(27, 6, 99, 0.4)',
  'rgba(95, 104, 1, 0.4)',
  'rgba(100, 7, 150, 0.4)',
  'rgba(15, 127, 2, 0.4)',
  'rgba(69, 2, 114, 0.4)',
  'rgba(6, 47, 112, 0.4)',
  'rgba(9, 100, 102, 0.4)',
]
